
.navbar {
    background-color: var(--secondary-color);
    color: white;
    height: 70px;
    user-select: none;
    z-index: 1000;
}


.navbar .flex {
    justify-content: space-between;
    align-items: center;
}


.logo img {
    max-width: 40px;
}

.logo-text {
    margin-bottom: 7px;
}

.logo-img {
    pointer-events: none;
    user-select: none;
}

.navbar ul {
    list-style-type: none;
    display: flex;
}

.navbar a {
    color: white;
    padding: 10px;
    margin: 0 5px;
}

.navbar a:hover {
    border-bottom: 2px white solid;
}

.active {
    border-bottom: 2px white solid;
}

@media (max-width: 500px) {
    .navbar {
        height: 110px;
    }

    .navbar .container {
        flex-direction: column;
    }

    .navbar nav {
        margin-bottom: 1rem;
    }
}