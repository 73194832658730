.arrow-down {
  width: 0;
  height: 0;
  border-left: 100vw solid transparent;
  border-right: 100vw solid transparent;
  border-top: 400px solid var(--secondary-color);
  position: fixed;
  left: -50%;
  top: 0;
  animation: slideInFromTop 400ms ease-in;
  z-index: -1;
}

.top-text h1 {
  position: relative;
  width: max-content;
  animation: fadeIn 1s ease-in;
}

.top-text p {
}

.arrow-section {
  z-index: -2;
}

.arrow-section .top-text {
  margin-top: 0;
  animation: fadeIn 1s ease-in;
  margin-bottom: 3rem;
}

.card-action {
  display: flex;
  justify-content: space-between;
}

.portfolio-cards {
  min-height: calc(100vh - 300px);
}

.portfolio-cards .flex {
  flex-wrap: wrap;
  justify-content: center;
}

.portfolio-card {
  padding: 0;
  overflow: hidden;
  background-image: var(--glass-gradient);
  background-color: transparent;
  animation: fadeIn 100ms ease-in;
  width: clamp(30ch, 50ch, 90vw);
  min-width: 270px;
  height: max-content;
  margin: 0 1rem 2rem 1rem;
}

.portfolio-card .card-body,
.card-action {
  padding: 0.5rem 1rem;
}

.portfolio-card img {
  width: 100%;
}

.content {
}

.content .card {
  padding: 1rem 5rem 1rem 2rem;
  text-align: center;
  transition: 500ms;
  cursor: pointer;
  background: var(--glass-gradient);
  width: 30rem;
  max-width: 80vw;
  align-items: center;
}

.content .card-text {
  margin-right: auto;
}

.content .card:hover {
  transform: translateY(-5%);
}

@media (max-width: 768px) {
  /*.content .container {*/
  /*    overflow: hidden;*/
  /*}*/

  .content .container .grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .content .card {
    margin: 0 2rem;
  }

  .slide-right {
    animation: slideInFromRight 500ms ease-in;
  }
  .slide-left {
    animation: slideInFromLeft 500ms ease-in;
  }
  .content {
    height: 100%;
  }
}

@media (max-width: 320px) {
  .top-text {
    width: 100%;
  }
  .top-text h1 {
    width: 100%;
  }
}
