.headline {
  height: 475px;
  background-image: var(--main-gradient);
  color: white;
  position: relative;
}

.headline-text {
  animation: slideInFromLeft 200ms ease-in-out;
}

.headline h1, .headline h2 {
  text-align: center;
}

.headline h1 {
  font-size: 2rem;
}

.headline p {
  margin: 20px 0;
}

.headline .grid {
  grid-template-columns: 55% 45%;
  gap: 30px;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.contact-form {
  position: relative;
  top: 60px;
  height: 350px;
  padding: 20px;
  z-index: 100;
  animation: slideInFromRight 200ms ease-in-out;
}

.contact-form .form-control {
  margin: 30px 0;
}

.contact-form input[type="text"],
.contact-form input[type="email"] {
  border: 0;
  border-bottom: 1px solid #b4becb;
  width: 100%;
  padding: 3px;
  font-size: 16px;
}

.contact-form input[type="submit"] {
  font-size: 1em;
  margin-left: auto;
}

.contact-form input:focus {
  outline: none;
}

.headline::before,
.headline::after {
  content: "";
  position: absolute;
  bottom: -70px;
  right: 0;
  left: 0;
  background-color: white;
  height: 100px;
  width: 100vw;
  transform: skewY(-3deg);
  -webkit-transform: skewY(-3deg);
  -moz-transform: skewY(-3deg);
  -ms-transform: skewY(-3deg);
}

.languages-link {
  text-decoration: underline;
}

.languages-link:hover {
  filter: brightness(0.8);
}

.stats {
  padding-top: 100px;
  color: #333;
  overflow: hidden;
}

.stats .icon {
  color: #333333;
}

.stats-heading {
  max-width: 500px;
  margin: auto;
  line-height: 1.3;
}

.stats-heading::before {
  content: open-quote;
  font-size: 2rem;
  vertical-align: middle;
}

.stats-heading::after {
  content: close-quote;
  font-size: 2rem;
  vertical-align: middle;
}

.stats .grid h3 {
  font-size: 35px;
}

.stats .grid p {
  font-size: 20px;
  font-weight: bold;
}

.package .grid {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.package .grid > *:first-child {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
}

.fullstack .grid {
  grid-template-columns: 4fr 3fr;
}

.cloud-img {
  pointer-events: none;
  user-select: none;
}

.languages .flex {
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.languages .card {
  text-align: center;
  margin: 0.5em;
  transition: transform 0.2s ease-in;
  font-size: 18px;
  width: 5em;
  padding: 1em 0.3em;
}

@media (max-width: 400px) {
  .languages .card {
    font-size: 14px;
    margin-top: 0.5rem;
  }
}

.languages .card h4 {
  font-size: 1em;
  margin-bottom: 10px;
}

.languages .card img {
  width: 4em;
  border-radius: 10px;
}

.languages .card:hover {
  transform: scale(1.1);
}

.footer a {
  margin: 0 10px;
  color: var(--light-color);
}

.footer a:hover {
  color: white;
}

.footer nav ul {
  list-style-type: none;
  color: var(--light-color);
}

.footer {
  color: var(--light-color);
}

.footer .grid > *:first-child {
  grid-column: 1 / span 2;
  /*grid-row: 1 / span 2;*/
}

.footer .container {
  width: 100%;
}

.disclaimer {
  font-size: 0.8rem;
  margin: -3rem auto 3rem auto;
  width: 80%;
  max-width: 600px;
}

.code-block {
  height: 100%;
  background-color: #3e3e3e;
  color: lime;
  padding: 1rem;
  margin: 0 10px;
  border-radius: 5px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
}

.package .container h3 a {
  border-bottom: 2px solid #333;
}

.package .container h3 a:hover {
  color: var(--light-color);
  border-bottom: 2px solid var(--light-color);
}

/* Tablets and below */
@media (max-width: 768px) {
  .grid,
  .headline .grid,
  .stats .grid,
  .package .grid,
  .fullstack .grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .headline {
    height: auto;
  }

  .headline-text {
    text-align: justify;
    margin-top: 40px;
    z-index: 50;
    animation: slideInFromBottom 300ms ease-in;
  }

  .package .grid > *:first-child {
    grid-column: 1;
    grid-row: 1;
  }

  .footer .grid > *:first-child {
    grid-column: 1;
  }

  .languages .card img {
    max-width: 64px;
  }

  .footer {
    text-align: center;
  }

  .footer .container .copyright {
    padding-left: 9px;
  }

  .headline::before,
  .headline::after {
    transform: skewY(-6deg);
    -webkit-transform: skewY(-6deg);
    -moz-transform: skewY(-6deg);
    -ms-transform: skewY(-6deg);
    bottom: -30px;
  }

  .contact-form {
    animation: slideInFromBottom 500ms ease-in;
    margin-top: -2.5rem;
  }
}
