@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

[data-inviewport="scale-in"] {
    transition: 1s;
    opacity: 0;
    transition-delay: 200ms;
    transform: scale(0.8);
}

[data-inviewport="scale-in"].is-inViewport {
    transform: scale(1);
    opacity: 1;
}


@media (max-width: 768px) {
[data-inviewport="slide-in-from-left"] {
    transform: translateX(-100%);
}

[data-inviewport="slide-in-from-left"].is-inViewport {
    animation: slideInFromLeft 300ms ease-in;
    transform: translateX(0);
}

}

[data-inviewport="slide-in-from-right"] {
    animation: slideInFromRight 300ms ease-in 200ms;
}

[data-inviewport="slide-in-from-right"].is-inViewport {
    transform: translateX(0);
}


/*Loading*/
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  color: white;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--primary-color);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primary-color) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
