@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

html {
  scroll-behavior: smooth;
}

:root {
  --primary-color: #a64dff;
  --secondary-color: #8c1aff;
  --dark-color: #582297;
  --light-color: #d5b3ff;
  --main-gradient: linear-gradient(0deg, #a64dff 20%, #8c1aff);
  --main-gradient-reversed: linear-gradient(0deg, #8c1aff 20%, #a64dff);
  --glass-gradient: linear-gradient(
    to right top,
    rgba(242, 230, 255, 0.6),
    rgba(244, 240, 255, 0.8) 60%
  );
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  /*background-color: var(--secondary-color);*/
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: var(--light-color);
  font-weight: 900;
}

h1,
h2 {
  font-weight: 300;
  line-height: 1.2;
  margin: 10px 0;
}

p {
  margin: 10px 0;
}

img {
  width: 100%;
}

body {
  color: #f2e6ff;
  line-height: 1.6;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  overflow: auto;
  padding: 0 40px;
}

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.gap-2 {
  gap: 2rem;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.align-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.max-width-60 {
  max-width: 60vw;
}

.z-index-top {
  z-index: 9999;
}

.card {
  background-color: white;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 10px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  justify-content: center;
  height: 100%;
}

.grid-align-centre {
  align-items: center;
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-4 {
  grid-template-columns: repeat(4, 1fr);
}

.btn {
  display: inline-block;
  padding: 0.7em 2em;
  cursor: pointer;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1em;
}

.btn-outline {
  background-color: transparent;
  border: 1px solid white;
}

.btn:hover {
  transform: scale(0.98);
}

/* Colors */
.bg-primary,
.btn-primary {
  background-color: var(--primary-color);
}

.bg-dark,
.btn-dark {
  background-color: var(--dark-color);
}

.bg-light,
.btn-light {
  background-color: var(--light-color);
}

.bg-grad {
  background-image: var(--main-gradient);
}

.btn-primary {
  background-image: var(--main-gradient-reversed);
}

.btn:disabled {
  filter: brightness(0.8);
  opacity: 0.8;
}

.btn:disabled:hover {
  transform: unset;
}

.text-dark {
  color: var(--dark-color);
}

.lead {
  font-size: 20px;
}

.xs {
  font-size: 0.8rem;
}

.sm {
  font-size: 1rem;
}

.md {
  font-size: 2rem;
}

.lg {
  font-size: 2.5rem;
}

.xl {
  font-size: 4rem;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 1.5rem 0;
}

.my-3 {
  margin: 2rem 0;
}

.my-4 {
  margin: 3rem 0;
}

.my-5 {
  margin: 4rem 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 1.5rem;
}

.m-3 {
  margin: 2rem;
}

.m-4 {
  margin: 3rem;
}

.m-5 {
  margin: 4rem;
}

.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 1.5rem 0;
}

.py-3 {
  padding: 2rem 0;
}

.py-4 {
  padding: 3rem 0;
}

.py-5 {
  padding: 4rem 0;
}

.pb-5 {
  padding: 0 0 4rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 1.5rem;
}

.p-3 {
  padding: 2rem;
}

.p-4 {
  padding: 3rem;
}

.p-5 {
  padding: 4rem;
}

.max-width {
  width: max-content;
}

.toast {
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  width: 300px;
  max-height: 90vh;
  overflow-y: auto;
  font-family: monospace;
  z-index: 9999;
  padding: 1rem 1rem;
}

.toast-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.toast-container-item {
  border: var(--primary-color) solid 1px;
  margin: 5px 5px;
  padding: 2rem;
  border-radius: 7px;
  width: 100%;
  min-height: 3rem;
  word-wrap: break-word;
  background-color: black;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  color: white;
  position: relative;
}

.toast-close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0.5rem;
  font-size: 20px;
  font-weight: 800;
}

.success {
  background-color: #79a079;
}

.info {
  background-color: #af90fd;
}

.danger {
  background-color: #ff47c4;
}

@media (min-width: 768px) {
  .md-hidden {
    display: none;
  }
}

@media (max-width: 768px) {
  .sm-hidden {
    display: none;
  }

  .max-width-60 {
    max-width: unset;
  }
}

@media (max-width: 1040px) {
  .btn {
    font-size: 0.9em;
  }
}
